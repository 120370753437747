<!-- group-edit -->
<template>
  <el-dialog
    class="group-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="group-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="分组名称:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="上级分组:">
        <group
          v-if="whetherRenderGroupCom"
          ref="group"
          v-model="model.pid"
          check-strictly
          :hidden-callback="groupTreeHiddenCallback"
          @on-ready="handleGroupComReady"
        />
      </el-form-item>

      <el-form-item label="排序号:" prop="sortNo">
        <el-input
          v-model.number="model.sortNo"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item label="备注:">
        <el-input
          v-model.trim="model.remark"
          type="textarea"
          :rows="3"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="group-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import Group from '../../../../../components/group';
  import { isNonNegativeIntString } from '../../../../../util/validator';
  import {
    getGroup,
    addGroup,
    updateGroup,
  } from '../../../../../api/auth/user';

  // 递归获取分组中指定节点的全部后代节点
  const recursiveChildren = (node, nodeList, arr = []) => {
    if (!node || !Array.isArray(nodeList) || !nodeList.length) return [];

    const { _id } = node;
    const children = nodeList.filter(item => item.pid === _id);

    if (!children || !children.length) return arr;

    arr.push(...children);

    children.forEach(child => recursiveChildren(child, nodeList, arr));

    return arr;
  };

  // 非负整数校验
  const nonNegativeInteger = (rule, value, callback) => {
    if (!value) callback();

    isNonNegativeIntString(value)
      ? callback()
      : callback(new Error('请填写非负整数'));
  };

  export default {
    name: 'group-edit',
    components: {
      Group,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 分组 id
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        // 是否渲染 group 组件，其需要在每次渲染时获取最新的全量分组列表
        whetherRenderGroupCom: false,
        // group 组件获取到的全量分组列表
        groupComList: [],
        // 当前编辑的分组对象
        group: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          sortNo: [{ validator: nonNegativeInteger, trigger: 'change' }],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.id;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}分组`;
      },
      // 当前编辑的节点及其全部后代节点
      editNodeAndDescendantNodes() {
        const currNode = this.group;

        if (!this.isEdit || !currNode) return [];

        return [currNode, ...recursiveChildren(currNode, this.groupComList)];
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const { name = '', pid = '', sortNo = '', remark = '' } = data || {};

        this.model = {
          name,
          pid,
          sortNo,
          remark,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.id,
        };
        const method = this.isEdit ? updateGroup : addGroup;
        const res = await method(params);

        return !!res;
      },
      // 分组组件不显示相应节点的回调函数
      groupTreeHiddenCallback(groupNode) {
        const { _id } = groupNode;

        return this.editNodeAndDescendantNodes.some(item => item._id === _id);
      },
      // 获取分组对象详情
      async getGroup() {
        const res = await getGroup(this.id);

        if (!res) return false;

        this.group = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        this.loading = true;

        // 如果是编辑，则先获取分组对象
        this.isEdit && (await this.getGroup());
        // 重新渲染 group 组件，确保其数据是最新的(与分组列表相匹配)
        this.whetherRenderGroupCom = true;

        this.initModel(this.group);
      },
      // 分组组件就绪
      async handleGroupComReady(groupTree, groupList) {
        this.groupComList = groupList;
        this.loading = false;
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.whetherRenderGroupCom = false;
        this.groupComList = [];
        this.group = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .group-edit {
    .group-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
