// 返回类型的字符串名称
export const getType = val => {
  const typeStr = Object.prototype.toString.call(val);
  const indexOfSpace = typeStr.indexOf(' ');

  return typeStr.substring(indexOfSpace + 1, typeStr.length - 1);
};

// 判断是否是 Undefined 类型
export const isUndefined = val => getType(val) === 'Undefined';

// 判断是否是 Null 类型
export const isNull = val => getType(val) === 'Null';

// 判断是否是 String 类型
export const isString = val => getType(val) === 'String';

// 判断是否是 Number 类型
export const isNumber = val => getType(val) === 'Number';

// 判断是否是 Boolean 类型
export const isBoolean = val => getType(val) === 'Boolean';

// 判断是否是 Array 类型
export const isArray = val => getType(val) === 'Array';

// 判断是否是普通 Object 类型
export const isPlainObject = val => getType(val) === 'Object';

/**
 * @description 空校验，空字符串算作空，NaN算作空，空数组算作空，不包含自有属性的普通对象算作空
 * @param {*} val
 * @returns {Boolean}
 */
export const isNullOrEmpty = val => {
  if (isNull(val) || isUndefined(val)) return true;

  if (isString(val)) return val === '';

  if (isNumber(val)) return Number.isNaN(val);

  if (isArray(val)) return !val.length;

  if (isPlainObject(val)) return !Object.keys(val).length;

  return false;
};

// 是否包含空值
export const includesNullOrEmpty = (...values) => {
  return values.some(item => isNullOrEmpty(item));
};

// 判断字符串是否是数值
export const isNumberString = string => {
  const regExp = /^\-?(\d+\.)?\d+$/;

  return regExp.test(string);
};

// 判断字符串是否是正数
export const isPositiveNumberString = string => {
  return isNumberString(string) && +string > 0;
};

// 判断字符串是否是非正数
export const isNonPositiveNumberString = string => {
  return isNumberString(string) && +string <= 0;
};

// 判断字符串是否是负数
export const isNegativeNumberString = string => {
  return isNumberString(string) && +string < 0;
};

// 判断字符串是否是非负数
export const isNonNegativeNumberString = string => {
  return isNumberString(string) && +string >= 0;
};

// 判断字符串是否是浮点数
export const isFloatString = string => {
  const regExp = /^\-?\d+\.\d+$/;

  return regExp.test(string);
};

// 判断字符串是否是正浮点数
export const isPositiveFloatString = string => {
  return isFloatString(string) && +string > 0;
};

// 判断字符串是否是非正浮点数
export const isNonPositiveFloatString = string => {
  return isFloatString(string) && +string <= 0;
};

// 判断字符串是否是负浮点数
export const isNegativeFloatString = string => {
  return isFloatString(string) && +string < 0;
};

// 判断字符串是否是非负浮点数
export const isNonNegativeFloatString = string => {
  return isFloatString(string) && +string >= 0;
};

// 判断字符串是否是整数
export const isIntString = string => {
  const regExp = /^\-?\d+$/;

  return regExp.test(string);
};

// 判断字符串是否是正整数
export const isPositiveIntString = string => {
  return isIntString(string) && +string > 0;
};

// 判断字符串是否是非正整数
export const isNonPositiveIntString = string => {
  return isIntString(string) && +string <= 0;
};

// 判断字符串是否是负整数
export const isNegativeIntString = string => {
  return isIntString(string) && +string < 0;
};

// 判断字符串是否是非负整数
export const isNonNegativeIntString = string => {
  return isIntString(string) && +string >= 0;
};

// 判断字符串是否是有效的手机号码
export const isTelephoneString = string => {
  const regExp = /^1[3456789]\d{9}$/;

  return regExp.test(string);
};
