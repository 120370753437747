// 用于 cascade 的 mixin
export default {
  props: {
    // 选中项 value
    value: {
      type: [String, Number, Array],
      default: '',
    },
    // 占位符文案
    placeholder: {
      type: String,
      default: '请选择',
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      default: true,
    },
    // medium/small/mini
    size: {
      type: String,
      default: '',
    },
    // 作为 label 的 key
    labelKey: {
      type: String,
      default: 'label',
    },
    // 作为 value 的 key
    valueKey: {
      type: String,
      default: 'value',
    },
    // 作为 children 的 key
    childrenKey: {
      type: String,
      default: 'children',
    },
    // 作为选项 disabled 的 key
    disabledKey: {
      type: String,
      default: 'disabled',
    },
    // 是否禁用某一项的回调函数，参数为节点数据对象
    disabledCallback: {
      type: Function,
      default: undefined,
    },
    // 是否隐藏某一项的回调函数，参数为节点数据对象
    hiddenCallback: {
      type: Function,
      default: undefined,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 是否可选任意级别的节点，默认 false，表示选中结果会最终到叶子节点
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    /* 在选中项改变时，是否返回该项及其祖先项的值所组成的数组
       若为 false，则只返回该节点自身的值 */
    emitPath: {
      type: Boolean,
      default: false,
    },
    // 是否显示选择项的完整路径
    showAllLevels: {
      type: Boolean,
      default: true,
    },
    // 选项路径间的分隔符
    separator: {
      type: String,
      default: '/',
    },
    // 多选模式下是否折叠 Tag
    collapseTags: {
      type: Boolean,
      default: true,
    },
    // 是否可搜索
    filterable: {
      type: Boolean,
      default: true,
    },
    // 自定义搜索函数
    filterMethod: {
      type: Function,
      default: undefined,
    },
    // 搜索时的防抖时长，单位: ms
    debounce: {
      type: Number,
      default: 300,
    },
    // 展开子节点的触发方式
    expandTrigger: {
      validator: val => ['click', 'hover'].includes(val),
      default: 'click',
    },
  },
  data() {
    return {
      selected: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(n) {
        n !== this.selected && (this.selected = n || '');
      },
    },
  },
};
